<template>
  <v-container>
    <h1 class="heading">{{ $t('COPPER_APPS.LOGIN_HEADING') }}</h1>
    <v-alert
      dark
      dense
      color="red lighten-2"
      v-if="error"
    >
      {{ error }}
    </v-alert>
    <v-form v-model="valid">
      <v-row>
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="email"
            type="email"
            :label="$t('AUTH.INPUT.EMAIL')"
            :rules="[
              v => !!v || $t('AUTH.LOGIN.VALID_EMAIL'),
              v => /.+@.+/.test(v) || $t('AUTH.LOGIN.VALID_EMAIL'),
            ]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="password"
            type="password"
            :label="$t('AUTH.INPUT.PASSWORD')"
            :rules="[
              v => !!v || $t('AUTH.LOGIN.VALID_PASSWORD')
            ]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pb-0 pt-0 d-flex"
        >
          <v-btn
            rounded
            color="primary"
            dark
            :loading="loading"
            @click="login()"
          >
            {{ $t('AUTH.LOGIN.LOGIN_BUTTON') }}
          </v-btn>
          <v-spacer />
          <GoogleLoginButton
            @loginResponse="sendLoginRequest($event, 'google')"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { LOGIN, GOOGLE_LOGIN, GET_CURRENT_USER, LOGOUT } from "@/store/auth.module";
import CopperContextService from '@/common/copper-context.service';
import GoogleLoginButton from "@/views/partials/form/GoogleLoginButton.vue"

export default {
  components: { GoogleLoginButton },

  data() {
    return {
      valid: false,
      email: '',
      password: '',
      error: false,
      loading: false
    };
  },

  mounted() {
    if (!this.$route.query.previousRoute) {
      this.$router.push({ name: 'login' });
      return;
    }
  },

  methods: {
    login() {
      if (this.valid) {
        this.sendLoginRequest({
          username: this.email,
          password: this.password
        });
      }
    },

    async sendLoginRequest(params, type = 'form') {
      this.loading = true;

      let method = type === 'google' ? GOOGLE_LOGIN : LOGIN;

      this.$store
        .dispatch(method, params)
        .then(() => {
          this.$store
              .dispatch(GET_CURRENT_USER)
              .then(async (data) => {
                let copperEmail = await CopperContextService.getLoggedUserEmail();
                if (copperEmail !== data.email) {
                  this.error = this.$t('COPPER_APPS.DIFFERENT_EMAIL_ERROR');
                  this.loading = false;

                  this.$store.dispatch(LOGOUT);

                  return;
                }

                this.$router.push(this.$route.query.previousRoute);
              });


        })
        .catch(() => {
          this.error = this.$t('AUTH.LOGIN.CREDENTIALS_ERROR');

          this.loading = false;
        });
    }
  }
}
</script>